<template>
    <div class="mentalitycate">
        <div class="mentality-top">
            <div class="logo" @click="goto('/')">
                <div class="picbox">
                    <div class="pic" style="background-image: url(/img/logo.png)"></div>
                </div>
            </div>
            <div class="titlebox">心理科普</div>
        </div>
        <div class="contentbox">
            <div class="left-con">
                <div class="menu" v-bind:class="{ 'active': gundong > gundongMax }">
                    <div class="titlebox">{{ cateName }}</div>
                    <div class="itemlist">
                        <div class="item" :class="{ active: cateIndex === index }"
                            v-for="(item, index) in catelist[this.$route.query.openKey]" :key="index"
                            @click="cateChan(index)">
                            <div class="picbox">
                                <div class="pic"
                                    :style="{ backgroundImage: 'url(/img/mentalitycate' + (index + 1) + '_cur.png)' }"
                                    v-if="cateIndex === index"></div>
                                <div class="pic"
                                    :style="{ backgroundImage: 'url(/img/mentalitycate' + (index + 1) + '.png)' }"
                                    v-else>
                                </div>
                            </div>
                            <div class="txt">{{ item.cateVal }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-con">
                <router-view :title="cateVal" :cate="cate" />
                <div class="right-qrcode">
                    <div class="picbox">
                        <div class="pic" style="background-image: url(/img/right_qrcode.png)"></div>
                    </div>
                    <img :src="baseUrl + base.wxLogo" alt="扫一扫">
                    <!-- <div style=" font-weight:bold; text-align:center; font-size: 20px;color: #ffffff">
                        扫码继续
                    </div>
                    <div class="qr-c">
                        <div ref="qrCode"></div>
                    </div> -->
                </div>
            </div>
        </div>
       
    </div>
</template>

<script>
import { getBase, getCategory } from "@/js/request";
// import QRCode from 'qrcode';
import { isPhone } from "../utils/tool"


export default {
    name: 'MentalityCate',
    components: {
    },
    data() {
        return {
            base: {},
            baseUrl: '',
            gundong: 0,
            gundongMax: 0,
            cate: 0,
            cateIndex: 0,
            cateVal: '全部',
            catelist: [
                // // 科普文章
                // [{ cate: 0, cateVal: '全部11' }, { cate: 1, cateVal: '情绪管理' }, { cate: 14, cateVal: '婚恋家庭' }, { cate: 15, cateVal: '儿童教育' }, { cate: 16, cateVal: '老年人心理健康' }, { cate: 18, cateVal: '焦虑抑郁' }, { cate: 19, cateVal: '失眠' }, { cate: 20, cateVal: '人际关系' }, { cate: 21, cateVal: '社会适应' }, { cate: 22, cateVal: '自我了解' }, { cate: 23, cateVal: '心理困扰' }],
                // // 科普漫画
                // [{ cate: 0, cateVal: '全部' }, { cate: 34, cateVal: '情绪管理' }, { cate: 35, cateVal: '婚恋家庭' }, { cate: 36, cateVal: '儿童教育' }, { cate: 37, cateVal: '老年人心理健康' }, { cate: 38, cateVal: '焦虑抑郁' }, { cate: 39, cateVal: '失眠' }, { cate: 40, cateVal: '人际关系' }, { cate: 41, cateVal: '社会适应' }, { cate: 42, cateVal: '自我了解' }, { cate: 43, cateVal: '心理困扰' }],
                // // 科普视频
                // [{ cate: 0, cateVal: '全部' }, { cate: 24, cateVal: '情绪管理' }, { cate: 25, cateVal: '婚恋家庭' }, { cate: 26, cateVal: '儿童教育' }, { cate: 27, cateVal: '老年人心理健康' }, { cate: 28, cateVal: '焦虑抑郁' }, { cate: 29, cateVal: '失眠' }, { cate: 30, cateVal: '人际关系' }, { cate: 31, cateVal: '社会适应' }, { cate: 32, cateVal: '自我了解' }, { cate: 33, cateVal: '心理困扰' }],
                // // 常见心理问与答
                // [{ cate: 0, cateVal: '全部' }, { cate: 44, cateVal: '情绪管理' }, { cate: 45, cateVal: '婚恋家庭' }, { cate: 46, cateVal: '儿童教育' }, { cate: 47, cateVal: '老年人心理健康' }, { cate: 48, cateVal: '焦虑抑郁' }, { cate: 49, cateVal: '失眠' }, { cate: 50, cateVal: '人际关系' }, { cate: 51, cateVal: '社会适应' }, { cate: 52, cateVal: '自我了解' }, { cate: 53, cateVal: '心理困扰' }]
            ],
            cateName: '',
        }
    },
    created() {
        this.cateName = this.$route.query.cateName;
    },
    methods: {
        goto(adress) {
            this.$router.push(adress);
        },
        cateChan(index) {
            this.cateIndex = index;
            this.cate = this.catelist[this.$route.query.openKey][index].cate;
            this.cateVal = this.catelist[this.$route.query.openKey][index].cateVal;
        },
        getScrollTop() {
            var st = window.pageXOffset
                || document.documentElement.scrollTop
                || document.body.scrollTop
                || 0;
            return st;
        },
        handleScroll() {
            this.gundong = this.getScrollTop();
        },
    },
    mounted() {
        // this.$refs.qrCode.innerHTML = '';
        // QRCode.toCanvas(window.location.href, { errorCorrectionLevel: 'H', width: 100 }, (err, canvas) => {
        //     if (err) throw error
        //     this.$refs.qrCode.appendChild(canvas)
        // });
        if(isPhone()){
            this.$root.hideRight();
        }else{
            this.$root.showRight();
        }
        if (this.$route.query.id) {
            this.cate = -1;
            this.cateIndex = -1;
            this.cateVal = "";
        }
        getBase().then(res => {
            this.base = res.data;
            this.baseUrl = res.config.baseURL;
        });
        getCategory(2).then(res => {
            var arr = new Array();
            arr[0] = { cate: 0, cateVal: '全部' };
            var arrin = 1;
            res.data.forEach(element => {
                arr[arrin] = { cate: element.id, cateVal: element.title };
                arrin++;
            });
            this.catelist[0] = arr;
        });
        getCategory(5).then(res => {
            var arr = new Array();
            arr[0] = { cate: 0, cateVal: '全部' };
            var arrin = 1;
            res.data.forEach(element => {
                arr[arrin] = { cate: element.id, cateVal: element.title };
                arrin++;
            });
            this.catelist[2] = arr;
        });
        getCategory(6).then(res => {
            var arr = new Array();
            arr[0] = { cate: 0, cateVal: '全部' };
            var arrin = 1;
            res.data.forEach(element => {
                arr[arrin] = { cate: element.id, cateVal: element.title };
                arrin++;
            });
            this.catelist[1] = arr;
        });
        getCategory(7).then(res => {
            var arr = new Array();
            arr[0] = { cate: 0, cateVal: '全部' };
            var arrin = 1;
            res.data.forEach(element => {
                arr[arrin] = { cate: element.id, cateVal: element.title };
                arrin++;
            });
            this.catelist[3] = arr;
        });
        window.addEventListener("scroll", this.handleScroll, true); //监听滚动事件
        this.gundongMax = document.getElementsByClassName("contentbox")[0].offsetTop;
    }
}
</script>
  
<style lang="scss">
.mentalitycate {
    padding: 60px 0;
    width: 1400px;
    margin: 0 auto;

    .mentality-top {
        display: flex;
        align-items: center;

        .logo {
            @include sys-picbox(438px, 80px);
            cursor: pointer;
        }

        .titlebox {
            @include sys-font(42px, #000, bold);
            margin-left: 30px;
            padding-left: 30px;
            position: relative;

            &::before {
                content: '';
                width: 2px;
                height: 46px;
                background: #000;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }
    }

    .contentbox {
        display: flex;
        width: 100%;
        padding-top: 80px;
        position: relative;

        .left-con {
            position: relative;
            z-index: 5;

            .menu {
                @include sys-boxsize(300px, auto, 20px, $acolor);
                box-shadow: 0px 0px 50px 0px rgba(63, 101, 224, 0.05);
                padding: 20px 40px;
                box-sizing: border-box;
                position: absolute;
                top: 0;
                left: 0;

                &.active {
                    position: fixed;
                    top: 80px;
                    left: 260px;
                }

                .titlebox {
                    @include sys-text-hide(1);
                    @include sys-font(30px, #fff, bold);
                    line-height: 50px;
                    text-align: center;
                    max-width: 260px;
                    margin: 0 -20px;
                }

                .itemlist {
                    width: 100%;
                    margin-top: 20px;

                    .item {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 50px;
                        padding: 10px 20px 10px 30px;
                        box-sizing: border-box;
                        border-radius: 25px;
                        margin-bottom: 10px;
                        cursor: pointer;

                        &.active {
                            background: #fff;

                            .txt {
                                color: $acolor;
                            }
                        }

                        &:hover {
                            .txt {
                                text-decoration: underline;
                            }
                        }

                        @include sys-picbox(20px, 20px);

                        .picbox {
                            flex: none;
                        }

                        .txt {
                            flex: 1;
                            @include sys-text-hide(1);
                            @include sys-font(16px, #fff);
                            margin-left: 20px;
                        }
                    }
                }
            }
        }

        .right-con {
            width: 100%;
            padding-left: 340px;
            box-sizing: border-box;
            position: relative;
        }
    }
    // .qr-c{
    //     text-align:center;

    //     div{
    //         text-align: center;
    //     }

    // }
    // .qrcode {
    //     position: fixed;
    //     width: 120px;
    //     height: 150px;
    //     bottom: 60px;
    //     right: 90px;
    //     background: #3F65E0;
    //     @include sys-picbox(130px, 191px);
    // }
}
</style>